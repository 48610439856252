.turn-changes-modal
{
    width: 1100px;
    max-width: none!important;
}

.turn-changes-container
{
    height: 500px;
    font-size: 14px;
}

.turn-changes-bottom-filler
{
    height: 10px;
}

.turn-difference-table-row
{
    padding: 10px;
    border: 1px dotted #cccccc;
    margin-bottom: 10px;
}

.turn-difference-table-row:nth-child(odd)
{
    background-color: #efefef;
}

.turn-difference-row .change-name,
.turn-difference-row .fixed-column
{
    width: 25%
}

.turn-difference-heading
{
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 10px;    
}

.turn-difference-table
{
    display: table;
    width: 100%;
}

.turn-difference-row
{
    display: table-row;
}


.turn-difference-column
{
    display: table-cell;
}

.turn-difference-column.header
{
    font-weight: bold;
}

.turn-difference-column .before
{
    display: inline;
    color: #C0C0C0;
    text-decoration: line-through;
    margin-right: 5px;
}

.turn-difference-column .after
{
    display: inline;
    color: red;
}