.bm-burger-button 
{
    position: absolute;
    width: 20px;
    height: 20px;
    left: 20px;
    top: 20px;
}

.bm-burger-button-relative
{
    position: relative;
    width: 20px;
    height: 20px;
    left: 36px;
    top: 36px;
}

.bm-burger-bars 
{
    background: #0EB4A2;
}

.bm-cross-button 
{
    height: 24px;
    width: 24px;
}

.bm-cross 
{
    background: #bdc3c7;
}

.bm-menu 
{
    background: #25246A;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

.bm-item-list 
{
    padding: 0.8em;
}

.bm-item 
{
    display: block;
    color: #bdc3c7;
}

a.menu-item, a.menu-item:hover, a.menu-item:active, a.menu-item:visited
{
    color: white !important;
}

.bm-item-list hr
{
    background-color: white;
}